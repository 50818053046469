import { Link } from 'gatsby'
import React from 'react'
import Layout from '../components/layout'
import Seo from '../components/SEO'

import '../ui/apropos.css'

const post = {
  title: "Jeune artisan en bombe de bain, un moment de douceur respectueux pour la peau",
  description: 'Toi aussi, tu aimes les bombes de bain? Je produit chaque boule de bain à la main en petite quantité à chaque fois.',
  image: 'https://archimouss.ch/assets/images/logo/big.png'
}

const APropos = () => {
    
  return (
    <>
    <Seo title={post.title} description={post.description} image={post.image}/>
  
   
    <Layout>
        <div className='main'>
            <div className='main-content'>
                <div className='apropos-main-content'>
                    <div className='apropos-content'>
                        <h1>Jeune artisan en bombe de bain</h1>
                        <p>Toi aussi, tu aimes les bombes de bain?</p>
                        <p>Au début, je les créais pour mes amis et pour ma famille.</p>
                        <p>Les bombes de bain que je produisait ont connu un vrai succès chez mon entourage!</p>
                        <p>Aujourd'hui, j'ai décidé de partager ma passion et de les commercialiser au travers de cet e-commerce.</p>
                        <p>Mon mot d'ordre : Un moment de douceur respectueux pour la peau.</p>
                        <p>Malheureusement, la majorité des bombes de bains trouvées dans le commerce utilisent des produits abrasifs pour la peau.</p>
                        <p>Ne voulant plus consommer ce type de produit, j'ai commencé à créer mes propres bombes de bain.</p>
                        <p>À base d'huiles essentielles naturelles bio et de divers ingrédients respectueux de la peau.</p>
                        <p>Je produit chaque boule de bain à la main avec une petite production à chaque fois</p>
                        <p>L'amour mis dans mes créations est unique à chaque fournée</p>
                        <p>Je n'ai pas d'usine, pas d'assistant ni même de machine, du 100% fait main et maison</p>
                        <p>Les emballages sont en matière 100% recyclés pour le respect de l'environment</p>
                        <p>Ces bombes de bain sont un hobby, à côté je travaille dans l'informatique</p>
                        <p>Si tu as des questions, n'hésite pas à me contacter sur <a href="mailto:bruno@artimouss.ch">bruno@artimouss.ch</a></p>
                        <p>Je répondrais volontiers à toutes questions!</p>

                        <p>Tu peux consulter mes bombes de bains avec le liens ci-dessous <br></br>(les essayer c'est les adopter !)</p>
                        <p>Les emballages sont fait à partir de matière 100% recyclés</p>
                        <div class="button-container">
                            <Link class="accent-button" alt="Voir les bombes de bain" to="/bombe-bain">Voir les bombes de bain</Link>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </Layout>
    </>
  )
}

export default APropos